import { axios } from '@/utils/request'

export function getWebhooks() {
  return axios({
    url: '/api/configuration/folib/webhook',
    method: 'get'
  })
}

export function saveWebhook(data) {
  return axios({
    url: '/api/configuration/folib/webhook',
    method: 'put',
    data: data
  })
}

export function updateWebhook(data) {
  return axios({
    url: '/api/configuration/folib/webhook',
    method: 'post',
    data: data
  })
}

export function deleteWebhook(data) {
  return axios({
    url: '/api/configuration/folib/webhook',
    method: 'delete',
    data: data
  })
}

export function queryWebhookLog(query) {
  return axios({
    url: '/api/configuration/folib/webhook/log',
    method: 'get',
    params: query
  })
}

export function testWebhook(data) {
  return axios({
    url: '/api/configuration/folib/webhook/test',
    method: 'put',
    data: data
  })
}

export function deleteWebhookLog(data) {
  return axios({
    url: '/api/configuration/folib/webhook/log',
    method: 'delete',
    data: data
  })
}